import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../../components/Layout/SEO';
import Layout from '../../components/Layout/Layout';
import CaseStudyHeader from '../../components/CaseStudy/CaseStudyHeader';
import SectionLight from '../../components/shared/SectionLight';
import CaseStudySectionHeader from '../../components/CaseStudy/CaseStudySectionHeader';
import CaseStudySection from '../../components/CaseStudy/CaseStudySection';
import CaseStudyText from '../../components/CaseStudy/CaseStudyText';
import CaseStudyImage from '../../components/CaseStudy/CaseStudyImage';
import HeadingArrow from '../../components/shared/HeadingArrow';
import Contact from '../../components/shared/Contact';

import data from '../../data/ranker';

const RankerPage = ({ location }) => {
	const { name, description, techUsed, sourceLinks } = data;
	const { alt } = data.images.main;

	const { demoLink } = data;

	const links = (
		<div>
			<a href={demoLink.link}>{demoLink.text}</a>
		</div>
	);
	const imageData = useStaticQuery(imageQuery);
	return (
		<Layout location={location}>
			<SEO title='Ranker App' />
			<CaseStudyHeader
				title={name}
				text={description}
				image={imageData.mainImg}
				alt={alt}
				links={links}
				sourceLinks={sourceLinks}
				techUsed={techUsed}
			/>
			<SectionLight>
				<CaseStudySectionHeader>
					Making Decisions More Fun
				</CaseStudySectionHeader>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Project Overview</HeadingArrow>
						<p>
							Ranker is simple app built to tackle the challenge
							of filtering the overwhelming amount of decisions
							we're asked to make in modern life. The goal of the
							project was to create a platform that makes it
							easier to list options and create choices.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.tablet.childImageSharp.fluid}
						alt='ranker setup'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Building a Clean UI</HeadingArrow>
						<p>
							While doing research to build the concept of Ranker,
							I knew the app needed to have a clean, simple
							interface that worked well on mobile views. The UI
							had to feel responsive, easy to use, also have
							open-ended scoring criteria. I decided on a split
							form pattern that neatly groups each section of the
							form onto it's own page.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.design.childImageSharp.fluid}
						alt='ranker design'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Scoring the Options</HeadingArrow>
						<p>
							I organized user interaction to flow in a vertical
							direction that better accommodates mobile usability.
							After setting up a form, users run down a quick
							vertical list and score each item based on an
							open-ended scale of their choosing. Advanced options
							can be toggled on the form to give users greater
							control of their results.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.scores.childImageSharp.fluid}
						alt='ranker scores'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Math Can Be Fun</HeadingArrow>
						<p>
							Ranking hierarchy is displayed at the top of the
							Score page, so users can immediately see the result
							of their input. Below this, users can also view a
							detailed breakdown of each score category to build
							insight into how each score influenced the results.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.results.childImageSharp.fluid}
						alt='ranker results'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>
							Creating a Digital Memory Bank
						</HeadingArrow>
						<p>
							I developed a database solution that allows users to
							store rankings for long term use and provide for
							easier recall. To solve this challenge I wrote a
							custom backend server using Express and connected it
							to a flexible, cloud-based MongoDB database.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.save.childImageSharp.fluid}
						alt='ranker save'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Organizing the Results</HeadingArrow>
						<p>
							Users can utilize a their personal dashboard to view
							or edit forms at a later time. I designed custom
							sliding drawer components that give users a higher
							degree of interaction with the app. This also helped
							to neatly organizing the page into user created
							categories that maximize screen efficiency.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.dashboard.childImageSharp.fluid}
						alt='ranker dashboard'
					/>
				</CaseStudySection>
				<Contact
					title='Interested in Working Together?'
					linkText='Let Me Know'
				/>
			</SectionLight>
		</Layout>
	);
};

export default RankerPage;

const imageQuery = graphql`
	query RankerQuery {
		mainImg: file(
			relativePath: { eq: "case-study/ranker/ranker-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		design: file(
			relativePath: { eq: "case-study/ranker/ranker-design.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		tablet: file(
			relativePath: { eq: "case-study/ranker/ranker-tablet.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		scores: file(
			relativePath: { eq: "case-study/ranker/ranker-scores.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		results: file(
			relativePath: { eq: "case-study/ranker/ranker-results.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		save: file(relativePath: { eq: "case-study/ranker/ranker-save.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		dashboard: file(
			relativePath: { eq: "case-study/ranker/ranker-dashboard.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
